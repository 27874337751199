import YouTube from "react-youtube-embed"
import ReactPlayer from "react-player"
import React from "react"

const AchterGlas = ({showPage}) =>
    <div className="page achterglas" style={{transform:`translateX(-${showPage * 100}%`}}>
        <h3>Achter Glas</h3>
        <p>
            <i>Achter Glas</i> is een nummer van de Nederlandse band Tröckener Kecks.
        </p>
        <p>
            Het is oorspronkelijk uitgebracht op het album 'Eén op één Miljoen', in 1987, waarna het als single op de
            markt kwam. Daarna verscheen het nummer nog op het live-album 'Meer! Meer! Meer!' uit 1989.
        </p>
        <p>
            Het nummer wordt door fans beschouwd als een van de hoogtepunten uit het oeuvre van de band en was een
            absolute favoriet bij concerten.
        </p>
        <div className="youtubecontainer">
            <YouTube id="dtlXekm0wZU"/>
        </div>
        <p>
            <i>Achter Glas</i> gaat over een man die zijn vrouw en kinderen heeft verlaten en doelloos door het
            nachtelijke Amsterdam doolt. Hij wordt verleid door Bleke Jet, een prostituee, die zijn zorgen aanhoort en
            hem overhaalt terug te keren naar zijn gezin. Als de hoofdpersoon tijden later weer op zoek gaat naar Jet,
            kan hij het betreffende bordeel niet meer vinden. Navraag in een café leert dat Jet al veertig jaar eerder
            is omgekomen bij een brand, maar af en toe nog verschijnt.
        </p>
        <p>Zanger Rick de Leeuw zei in 2000 in een <a
            href="http://www.sietsemeijer.nl/niks-went-de-zoektocht-van-rick-de-leeuw/" target="_new">interview</a> met
            Het Parool: &ldquo;Dat is de mooiste tekst die Leo (Kenter, voormalig drummer en tekstschrijver bij de
            Tröckener Kecks) ooit voor ons heeft geschreven. Het roept niet alleen een beeld op, maar een heel verhaal,
            geen woord te veel, en het rijmt ook nog eens. Dat zou ik niet kunnen. Ik ben er zo trots op dat ik vergeet
            dat ik jaloers ben.&rdquo;</p>
        <span>
            <ReactPlayer url="https://soundcloud.com/user-229017089/trockener-kecks-achter-glas" width="90vw"/>
        </span>
    </div>

export default AchterGlas