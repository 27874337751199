import YouTube from "react-youtube-embed"

const BlekeJet = ({showPage}) =>
    <div className="page blekejet" style={{transform:`translateX(-${showPage * 100}%`}}>
        <h3>Bleke Jet</h3>
        <p>
            Het gegeven dat een overleden persoon een levende te hulp schiet, is bepaald niet nieuw in de popmuziek.
            Bleke Jet, die &lsquo;veertig jaar geleden&rsquo; omkwam in een brand, verleidt af en toe nog mannen die
            haar doen denken aan haar laatste klant en biedt ze een luisterend oor.
        </p>
        <p>
            In 1967 bracht Red Sovine het nummer <i>Phantom 309</i> uit, over een man die midden in de nacht in de
            stromende regen wordt opgepikt door Big Joe in zijn vrachtwagen Phantom 309. Joe zet de hoofdpersoon af bij
            een wegrestaurant en geeft hem wat geld voor koffie. De klanten in het restaurant vertellen dat Big Joe tien
            jaar geleden is omgekomen, toen hij zijn vrachtwagen crashte in een poging een bus vol schoolkinderen te
            ontwijken. Phantom 309 werd later nog gecovered door Tom Waits, in 1981 kwam de Nederlandse B.B. Band met
            een vertaling: <i>Stille Willie</i>.
        </p>
        <div className="youtubecontainer">
            <YouTube id="7a5jCK0zgBI"/>
        </div>
        <p>
            De grootste hit met dit thema was ongetwijfeld <i>Camouflage</i> van Stan Ridgway. Daarin raakt een jonge
            soldaat in Vietnam verdwaald en wordt omringd door de vijand, als hij wordt gered door een grote marinier
            die zich Camouflage noemt. Terwijl ze zich een weg terugvechten naar de thuisbasis, merkt de soldaat dat
            Camouflage niet gewond raakt door kogels. Later hoort hij dat Camouflage al een week op zijn sterfbed lag en
            de afgelopen nacht is overleden.
        </p>
        <div className="youtubecontainer">
            <YouTube id="ZFYxCIr-Byo"/>
        </div>
    </div>

export default BlekeJet