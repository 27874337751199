import Top from "./Top"
import AchterGlas from "./AchterGlas"
import BlekeJet from "./BlekeJet"
import Kecks from "./Kecks"
import {useState} from "react"

import "./App.css"

const Pages = () => {
    const [showPage, setShowPage] = useState(0)

    return <div className="container">
        <Top setShowPage={(i) => setShowPage(i)}/>
        <div className="pages">
            <AchterGlas showPage={showPage}/>
            <BlekeJet showPage={showPage}/>
            <Kecks showPage={showPage}/>
        </div>
    </div>
}

export default Pages